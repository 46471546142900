:global(#app) {
  .wrapper {
    background: rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 20px 0;
  }
  .wrapper1 {
    background: rgba(65,105,225, 0.7);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 20px 0;
  }
}
