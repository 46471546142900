:global(#app) {
  .button {
    background: transparent;
    box-shadow: none;
    color: #6b808c;
    font-weight: normal;
    margin-top: 8px;
    padding: 6px 11px;
    text-align: left;
    transition: none;

    &:hover {
      background: rgba(9, 30, 66, 0.08);
      color: #092d42;
    }
  }
}
