:global(#app) {
  .wrapper {
    position: fixed;
    width: 100%;
    z-index: 1;
  }
  .wrapper1 {
    margin-top: 64px;
    position: fixed;
    width: 100%;
    //z-index: 1;
  }
}
