:global(#app) {
  .actionButton {
    background: #ebeef0;
    box-shadow: 0 1px 0 0 rgba(9, 30, 66, 0.13);
    color: #444;
    margin-top: 8px;
    overflow: hidden;
    padding: 6px 8px 6px 18px;
    text-align: left;
    text-overflow: ellipsis;
    transition: background 85ms ease;
    white-space: nowrap;

    &:hover {
      background: #dfe3e6;
      box-shadow: 0 1px 0 0 rgba(9, 30, 66, 0.25);
      color: #4c4c4c;
    }
  }

  .actionIcon {
    color: #17394d;
    display: inline;
    margin-right: 8px;
  }

  .actions {
    margin-bottom: 24px;
  }

  .actionsTitle {
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.04em;
    margin-top: 16px;
    text-transform: uppercase;
    line-height: 20px;
    margin-bottom: -4px;
  }

  .addAttachment {
    margin: 0 -4.3px;
    text-decoration: none;
  }

  .attachment {
    display: inline-block;
    margin: 0 4px 4px 0;
    max-width: 100%;
  }

  .attachments {
    display: inline-block;
    margin: 0 8px 8px 0;
    max-width: 100%;
    vertical-align: top;
  }

  .contentModule {
    margin-bottom: 24px;
  }

  .contentPadding {
    padding: 8px 8px 0 16px;
  }

  .cursorPointer {
    cursor: pointer;
  }

  .dueDate {
    background: rgba(9, 30, 66, 0.04);
    border: none;
    border-radius: 3px;
    color: #6b808c;
    cursor: pointer;
    line-height: 20px;
    outline: none;
    padding: 6px 14px;
    text-align: left;
    text-decoration: underline;
    transition: background 0.3s ease;
    vertical-align: top;

    &:hover {
      background: rgba(9, 30, 66, 0.08);
      color: #17394d;
    }
  }

  .descriptionButton {
    background: rgba(9, 30, 66, 0.04);
    border: none;
    border-radius: 3px;
    display: block;
    color: #6b808c;
    cursor: pointer;
    min-height: 54px;
    outline: none;
    padding: 8px 12px;
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 100%;

    &:hover {
      background: rgba(9, 30, 66, 0.08);
      color: #092d42;
    }
  }

  .descriptionButtonText {
    position: absolute;
    top: 12px;
  }

  .descriptionText {
    background: transparent;
    border: none;
    color: #17394d;
    margin-bottom: 8px;
    outline: none;
    overflow: hidden;
    padding: 0;
    text-align: left;
    width: 100%;
  }

  .grid {
    background: #f5f6f7;
    margin: 0;
  }

  .headerPadding {
    padding: 0;
  }

  .headerTitle {
    color: #17394d;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .headerTitleWrapper {
    margin: 4px 0;
    padding: 6px 0 0;
  }

  .headerWrapper {
    margin: 12px 48px 12px 56px;
    position: relative;
  }

  .modalPadding {
    padding: 0px;
  }

  .moduleHeader {
    color: #17394d;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin: 0 0 4px;
    padding: 8px 0;
  }

  .moduleIcon {
    color: #17394d;
    font-size: 17px;
    height: 32px;
    left: -40px;
    line-height: 32px;
    margin-right: 0;
    position: absolute;
    top: 2px;
    width: 32px;
  }

  .moduleWrapper {
    margin: 0 0 0 40px;
    position: relative;
  }

  .sidebarPadding {
    padding: 8px 16px 0 8px;
  }

  .text {
    color: #6b808c;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin: 0 8px 4px 0;
    text-transform: uppercase;
  }

  .wrapper {
    width: 880px;
  }
  .wrapper1 {
    margin-left: 240px;
    //width: 880px;
  }
  @media (max-width: 926px) {
    .wrapper {
      width: 95%;
    }
  }
}
