:global(#app) {
  .dropzone {
    background: white;
    font-size: 20px;
    font-weight: 700;
    height: 100%;
    line-height: 30px;
    opacity: 0.7;
    padding: 200px 50px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 2001;
  }

  .wrapper {
    overflow: hidden;
  }
}
